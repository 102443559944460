import Home from "../pages/Home/Home";
import NotFound from "../pages/NotFound/NotFound";

/*
  Routes config must be ordered the same way you'd 
  do inside a `<Switch>`. The last route object is 
  a "fallback" route, to catch 404 errors.
*/

const RoutesConfig = [
  {
    path: "/",
    //protected route takes path and a render prop
    render: Home
    // component: Home,
  },
  {
    path: "*",
    component: NotFound
  }
];

export default RoutesConfig;
