import React from "react";

import styles from "./Projects.module.css";

export default function Projects(props) {
  const projects = [
    {
      title: "Maze Game",
      body:
        "A simple text based maze game. The maze is generated randomly. I worked with Django and Python to build the backend.",
      github: "https://github.com/cseu4-melqui-mud"
    },
    {
      title: "Dad Jokes",
      body:
        "A joke app for people with Dad humour. I worked with React and collaborated with the backend team to make a functioning and responsive app.",
      github: "https://github.com/dad-jokes-webeu"
    },
    {
      title: "Decksify",
      body:
        "An app designed to allow users to easily create, share, and browse study flashcard decks.",
      github: "https://github.com/Darragh1996?tab=stars"
    },
    {
      title: "Replate",
      body:
        "An app that allows food businesses to easily collaborate with local charities to feed the less fortunate with food that would otherwise go to waste.",
      github: "https://github.com/Replate-EU/Frontend/tree/master/front-end"
    }
  ];
  return (
    <div className={styles.projectsContainer}>
      <h2 className={styles.projectsHeader}>My Projects</h2>
      <ul>
        {projects.map(project => {
          return (
            <li>
              <a
                href={project.github}
                target="_blank"
                rel="noopener noreferrer"
              >
                <h2>{project.title}</h2>
                <p>{project.body}</p>
              </a>
            </li>
          );
        })}
      </ul>
      <div class={styles.arrow} onClick={() => props.scroll("about", "end")}>
        <span></span>
        <span></span>
      </div>
    </div>
  );
}
