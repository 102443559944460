import React from "react";
import styles from "./Introduction.module.css";

export default function Introduction(props) {
  return (
    <div id="home">
      <div className={styles.info}>
        <p className={styles.intro}>
          I'm <span className={styles.highlight}>Darragh</span>, a
          <span className={styles.highlight}>Web Developer</span> in the making.
          I like to approach problems with a{" "}
          <span className={styles.highlight}>logical</span> and{" "}
          <span className={styles.highlight}>creative</span> mindset.
        </p>
        <button
          className={styles.contact}
          onClick={() => props.scroll("contact")}
        >
          Contact Me
        </button>
      </div>
      <div class={styles.arrow} onClick={() => props.scroll("projects")}>
        <span></span>
        <span></span>
      </div>
    </div>
  );
}
