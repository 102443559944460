import React from "react";
import styles from "./Contact.module.css";

export default function Contact(props) {
  return (
    <div>
      <button className={styles.top} onClick={props.scrollToTop}>
        Back To Top
      </button>
      <div className={styles.flipCard}>
        <div className={styles.flipCardInner}>
          <div className={styles.flipCardFront}>
            <div className={styles.hello}>Say Hello</div>
          </div>
          <div className={styles.flipCardBack}>
            <div className={styles.businessCard}>
              <h2>Darragh Ferry</h2>
              <section>
                <h3>Email</h3>
                <p>
                  <a
                    href="mailto:ferrydarragh@gmail.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    ferrydarragh@gmail.com
                  </a>
                </p>
              </section>
              <section>
                <h3>LinkedIn</h3>
                <p>
                  <a
                    href="https://www.linkedin.com/in/darraghferry/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Darragh Ferry
                  </a>
                </p>
              </section>
              <section>
                <h3>Github</h3>
                <p>
                  <a
                    href="https://github.com/Darragh1996"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Darragh1996
                  </a>
                </p>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
