import React from "react";
import styles from "./About.module.css";

export default function About(props) {
  return (
    <div className={styles.container}>
      <h2>A Little About Me</h2>
      <p>
        <span className={styles.red}>I</span> grew up by the shore on the
        north-west coast of Ireland in a place called Donegal. I spent a lot of
        my youth climbing(and falling from) trees, swimming, and kayaking. As
        well as feeding chickens, tending to fences to make sure the cows or
        donkeys couldn’t run off, collecting seaweed, and turning turf in the
        bog.
      </p>
      <p>
        <span className={styles.red}>Now</span>, I hope to become a Web
        Developer. I’ve spent the best part of a year studying through the
        Lambda School program and have learnt a lot about Web Development and
        Computer Science. I hope to learn a lot more. I enjoy puzzle solving
        which is a main draw for me to the software industry. So if you need
        some problems solved my information is just down below.
      </p>
      {/* <p id={styles.ps}>
        The picture behind this text is the view from my house, although the
        sunny weather is a rare enough event here.
      </p> */}
      <div class={styles.arrow} onClick={() => props.scroll("contact", "end")}>
        <span></span>
        <span></span>
      </div>
    </div>
  );
}
