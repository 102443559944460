import React from "react";
import styles from "./Home.module.css";
import Introduction from "../../Components/Introduction";
import Projects from "../../Components/Projects";
import Contact from "../../Components/Contact";
import About from "../../Components/About";
import myView from "../../images/my_view.jpg";

function scrollToTop() {
  let element = document.getElementById("content");
  element.scrollIntoView({
    behavior: "smooth",
    block: "start",
    inline: "nearest"
  });
}

function scroll(id, scrollTo = "start") {
  let element = document.getElementById(id);
  element.scrollIntoView({
    behavior: "smooth",
    block: scrollTo,
    inline: "nearest"
  });
}

export default function Home() {
  return (
    <div id="content">
      <div className={styles.introduction}>
        <Introduction scroll={scroll} scrollToTop={scrollToTop} />
      </div>
      <div className={styles.projects} id="projects">
        <Projects scroll={scroll} />
      </div>
      <div className={styles.image}>
        <img alt="view of Arranmore from my house." src={myView} />
      </div>
      <div className={styles.aboutMe} id="about">
        <About scroll={scroll} />
      </div>
      <div className={styles.contact} id="contact">
        <Contact scrollToTop={scrollToTop} />
      </div>
    </div>
  );
}
